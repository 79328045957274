import * as util from "./util"

util.docReady(() => {
  // const $toggleButton = document.querySelector(".nav__toggler")!
  // const $toggleButton2 = document.querySelector(".nav__logo")!
  // const $toggleButton3 = document.querySelector(".nav__togglerClose")!

  const toggleAside = () => {
    document.body.classList.toggle("aside-shown")
  }

  for (const selector of [".nav__toggler", ".nav__logo", ".nav__togglerClose", ".mainContainer"]) {
    document.querySelector(selector)!.addEventListener("click", toggleAside)
  }

  document.querySelector(".mainContainer")?.addEventListener("click", (e) => {
    const classList = document.body.classList
    if (classList.contains("aside-shown")) {
      classList.remove("aside-shown")
    }
  })
})
